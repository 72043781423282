<template>
  <v-list-item
    v-if="item"
    :to="{ name: 'Product', params: { productSlug: item.product.slug } }"
  >
    <v-row no-gutters class="align-center">
      <v-col cols="12" md="6">
        <div class="d-flex flex-column">
          <!-- <ProductPromo
            :warehousePromo="item.product.warehousePromo"
            v-if="item.product.warehousePromo"
          ></ProductPromo> -->
          <div class="d-flex align-center product-column">
            <v-img
              :src="item.product.mediaURL"
              class="product-img d-xs-none"
              width="85px"
              height="85px"
              max-height="85px"
              max-width="85px"
            />
            <div class="product-info pl-2">
              <span class="product-name">{{ item.product.name }}</span>
              <span class="product-brand">{{ item.product.shortDescr }}</span>
              <div class="product-descr">{{ item.product.description }}</div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="4" md="2" class="justify-center">
        <ProductPrice :product="item.product"
      /></v-col>

      <v-col
        cols="7"
        md="3"
        class="d-flex flex-column justify-center align-center"
      >
        <template v-if="isActive">
          <div
            class="qty-wrapper"
            v-for="selectOption in selectOptionsArray"
            :key="selectOption.name"
            @click.stop.prevent
            @mousedown.stop
          >
            <v-select
              outlined
              dense
              v-model="selectedOptions[selectOption.parameterName]"
              :items="selectOption.options"
              :label="selectOption.name"
              item-text="key"
              item-value="value"
              @change="optionChanged(selectOption)"
              :menu-props="{ maxWidth: '55%' }"
            />
          </div>
          <product-qty
            v-if="item.product.productInfos.QTY_LOCKED != 'true'"
            :selectedOptions="selectedOptions"
            :product="item.product"
            :item="item1"
          />
          <v-spacer />
          <ProductQty
            v-if="item.product.productInfos.QTY_MULT > 1"
            :selectedOptions="selectedOptions"
            :product="item.product"
            :item="item2"
          />
        </template>
        <template v-else>
          <span class="error--text font-weight-bold"
            >Prodotto non acquistabile</span
          >
        </template>
      </v-col>
      <v-col cols="1" md="1" class="d-flex align-center justify-center">
        <v-btn
          small
          icon
          class="ml-1 delete-item-btn"
          v-on:click.prevent="removeItem()"
        >
          <v-icon>$delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-list-item>
</template>
<style scoped lang="scss">
.promo-box {
  margin-top: -10px;
}

.qty-wrap {
  background-color: #ededed;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0 1px;
  align-items: center;
  border-radius: $border-radius-root !important;
  transition: width 0.4s ease-in-out;
  overflow: hidden;
  justify-content: center;
  height: 52px;
  flex-grow: 1;
  margin-right: 5px;
  width: 100%;
  max-width: 150px;
  .plus {
    color: #515151;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1px;
    font-size: 12px;
  }
  .minus {
    color: #515151;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1px;
    font-size: 12px;
  }
  .v-icon {
    font-size: 12px;
    height: 22px;
    width: 22px;
  }
  .minus,
  .plus {
    height: 100%;
    min-width: 42px;
    text-decoration: none;
    &.disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
  .val-cnt {
    flex-grow: 1;
    text-align: center;
    color: #515151;
    font-size: 14px;
    flex-direction: column;
    line-height: 1.2;
    white-space: nowrap;

    .val {
      font-weight: bold;
    }
    .small {
      font-size: 12px;
    }
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice";
import ProductQty from "@/components//product/ProductQty.vue";
// import ProductPromo from "@/components/product/ProductPromo";
import CatalogService from "~/service/catalogService";
import CartService from "~/service/cartService";
import { mapActions, mapGetters } from "vuex";
import forEach from "lodash/forEach";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "ListItem",
  components: { ProductPrice, ProductQty },
  props: {
    item: { type: Object },
    isActive: { type: Boolean },
    selectedOptions: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      selectOptionsArray: []
    };
  },
  // mixins: [productMixin],
  computed: {
    quantity() {
      // let item = this.item;
      if (this.item === null || this.item.product === null) return 0;
      if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
        //Handling floating point decimals issues
        var val = (this.item.weight * 100).toFixed(0);
        val = val * CatalogService.getMultiplier(this.item.product);
        val = val / 100;
        return val;
      } else {
        return this.item.quantity;
      }
    },
    isPezzo: function() {
      return this.product?.productInfos?.TIPOLOGIA == "Pezzo";
    },
    quantityPerUnit() {
      let quantity = this.quantity;
      if (quantity === 0) return;
      if (
        this.item.product.productInfos.TIPOLOGIA === "Sfuso" &&
        this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
      ) {
        try {
          var singleUnit = Math.floor(
            quantity /
              parseFloat(
                this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
              )
          );
          var singleUnitUm = this.item.product.productInfos
            .WEIGHT_SELLING_SINGLE_UNIT_UM
            ? this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT_UM
            : "pz";
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      if (this.item.product.productInfos.TIPOLOGIA === "Confezione") {
        try {
          let singleUnit =
            quantity *
            parseFloat(this.item.product.productInfos.WEIGHT_SELLING);
          singleUnit = (singleUnit * 1000).toFixed(0);
          singleUnit = singleUnit / 1000;
          let singleUnitUm = this.item.product.productInfos.WEIGHT_UNIT_SELLING;
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      return null;
    },
    unit() {
      if (this.item.product.productInfos) {
        if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
          return this.item.product.productInfos.WEIGHT_UNIT_SELLING;
        } else {
          return this.item.product.productInfos.UNIT_SELLING != null
            ? this.item.product.productInfos.UNIT_SELLING
            : "pz";
        }
      } else {
        return null;
      }
    },
    ...mapGetters({
      getItem: "cart/getItem"
    }),
    item1() {
      let cartItem = this.getItem({
        product: this.item.product,
        ...this.selectedOptions
      });
      return cartItem;
    },
    item2() {
      let cartItem = this.getItem({
        product: this.item.product,
        ...this.selectedOptions
      });

      return cartItem;
    }
  },
  methods: {
    ...mapActions({
      addProduct: "cart/addProduct"
    }),
    removeItem() {
      this.$emit("removeItem", {
        itemId: this.item.itemId,
        product: this.item.product
      });
    },
    plus() {
      const newQuantity = CartService.plus(this.item.product, this.quantity);

      this.$emit("update", { newQuantity, item: this.item });
    },
    async minus() {
      const newQuantity = CartService.minus(this.item.product, this.quantity);

      this.$emit("update", { newQuantity, item: this.item });
    },
    addToCart() {
      this.addProduct({ product: this.item.product, quantity: this.quantity });
    },
    setCurrentlySelectedOption(value) {
      if (typeof value === "object" && value.name && value.parameterName) {
        this.currentOption = value;
      } else {
        this.currentOption = this.product.selectOptions.find(o => {
          return o.parameterName === value;
        });
      }
      console.log(this.currentOption);
    },
    handleSelectedOptions(result) {
      for (var i = 0; i < Object.keys(result).length; i++) {
        let key = Object.keys(result)[i];
        this.$set(this.selectedOptions, key, Object.values(result)[i]);
        this.setCurrentlySelectedOption(key);
        this.$set(
          this.selectedOptions,
          "notes",
          this.currentOption.name + ": " + this.currentOption.parameterName
        );
      }
    },
    optionChanged(option) {
      this.setCurrentlySelectedOption(option);
    }
  },
  mounted() {
    if (this.item.product.selectOptions) {
      this.selectOptionsArray = cloneDeep(this.item.product.selectOptions);
      forEach(this.selectOptionsArray, a => {
        a.options.unshift({ key: "-", value: "-" });
      });
    }
  }
};
</script>
